.loadingContainer {
  height: 100vh;

  img {
    animation: leaves 0.4s ease-in-out infinite alternate;
  }
}

@keyframes leaves {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
  }
}
