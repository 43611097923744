.login {
    height: 100vh;
    padding: 0 !important;
    margin: 0 !important;
    background-color: #FFF;
    display: flex !important;
    justify-content: center;
    align-items: center;

    form {
      padding: 2em 1em;
      background-color: #FFF;
      border: 1px solid #CCC;
      box-shadow: 0px 0px 20px 7px #00000045;
      max-width: 300px;

      input[type=text], input[type=password] {
        border: 1px solid #CCC;
      }
    }
}