$global-small-gutter: 8px;
$container-max-width: 1300px;
$form-width-large: 350px;
$global-border: #9e9e9e;
$badge-border-radius: 0px;

@import '~uikit/src/scss/variables-theme';

$badge-background: $global-success-background;

@import '~uikit/src/scss/mixins-theme';
@import '~uikit/src/scss/uikit-theme';

/**
REACT VIRTUALIZED
**/
.ReactVirtualized__Table__Grid {
  outline: none !important;
}

/**
UIKIT
**/
.uk-notification-message-success {
  background-color: $global-success-background;
  color: #FFF;
}

.uk-notification-message-danger {
  background-color: $global-danger-background;
  color: #FFF;
}

.uk-notification-message-warning {
  background-color: $global-warning-background;
  color: #FFF;
}

.uk-notification-message-primary {
  background-color: $global-primary-background;
  color: #FFF;
}

.uk-notification-close {
  color: #FFF;
}

.uk-button-small {
  padding-bottom: 3px;
}

/**
APP'S CSS
**/
.bg-success {
  background-color: $global-success-background;
}